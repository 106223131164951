<template>
  <div class="reg">
    <header class="reg-header">
      <img src="../assets/img/logo-pic.png" height="40" alt="bird" />
      <h1>创建您的候鸟账号</h1>
    </header>
    <section class="form">
      <label class="email">
        <input-outline
          ref="email"
          type="small"
          default-title="邮箱地址"
          v-model:input-value="email.value"
          check="email"
          :error="email.error"
          :error-tips="email.errorTips"
          v-on:check-result="checkEmail"
        />
      </label>
      <label class="label-tips">
        我们会向您的邮箱发送一封注册邮件用于验证, 请保证邮箱可以正 常接收邮件
      </label>
      <div class="password-container">
        <label class="password">
          <input-outline
            ref="password"
            check="password"
            type="small"
            default-title="密码"
            v-model:input-value="password.value"
            :error="password.error"
            :error-tips="password.errorTips"
            v-on:check-result="checkPassword"
          />
        </label>
        <label class="password-confirm">
          <input-outline
            type="small"
            check="password"
            default-title="确认密码"
            v-model:input-value="passwordConfirm.value"
            @focusout="checkCorrect"
            :error="passwordConfirm.error"
            :error-tips="passwordConfirm.errorTips"
          />
        </label>
      </div>
      <label class="label-tips">
        使用8个或更多字符 (字母、数字、符号的组合)
      </label>
      <div class="contact-container">
        <label class="contact">
          <a-dropdown class="contact-dropdown">
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              <span class="contact-span">
                {{ connect.name }}
              </span>
              <DownOutlined />
            </a>
            <template #overlay>
              <a-menu @click="changeContactMode">
                <a-menu-item key="weixin">
                  微信
                </a-menu-item>
                <a-menu-item key="skype">
                  skype
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <input type="text" class="contact-input" v-model="connect.value" />
        </label>
      </div>
      <label class="label-tips">
        填写您的联系方式，skype或微信
      </label>
      <div class="captcha-container">
        <label class="captcha">
          <input-outline
            ref="captcha"
            check="captcha"
            type="small"
            default-title="图形验证码"
            v-model:input-value="captcha.value"
            :error="captcha.error"
            :error-tips="captcha.errorTips"
            v-on:check-result="checkCaptcha"
          />
        </label>
        <div class="captcha-img">
          <img v-if="captcha.src" :src="captcha.src" title="看不清？点击刷新" @click="getCaptcha">
        </div>
      </div>
      <div class="label-tips">
        填写右边4位图片验证码
      </div>
      <div class="operation">
        <div class="login">
          <a href="https://www.mbbrowser.com/console/" target="_self"
            >登录现有账号</a
          >
        </div>
        <a-button class="operation-button" @click="reg" :loading="loadingShow">
          创建账号
        </a-button>
      </div>
    </section>
    <div class="tips">
      有账号方面的疑问,您可以<a href="#">联系我们的客服</a>
    </div>
  </div>
</template>

<script>
import InputOutline from "@/components/InputOutline";
import { DownOutlined } from "@ant-design/icons-vue";
import { user } from "../utils/api";
import { message } from "ant-design-vue";
export default {
  name: "TheReg",
  components: {
    InputOutline,
    DownOutlined
  },
  data() {
    return {
      email: {
        value: "",
        error: false,
        errorTips: "",
        checkResult: false
      },
      invitationCode: {
        value: "",
        error: false,
        errorTips: "",
        checkResult: false
      },
      password: {
        value: "",
        error: false,
        errorTips: "",
        checkResult: false
      },
      passwordConfirm: {
        value: "",
        error: false,
        errorTips: ""
      },
      connect: {
        value: "",
        error: false,
        errorTips: "",
        mode: "weixin",
        name: "微信"
      },
      captcha: {
        value: "",
        error: false,
        errorTips: "",
        checkResult: false,
        src: "",
        key: ""
      },
      loadingShow: false
    };
  },
  methods: {
    // 检测密码是否一致
    checkCorrect() {
      if (this.password.value === this.passwordConfirm.value) {
        return true;
      } else {
        this.passwordConfirm.error = true;
        this.passwordConfirm.errorTips = "两次输入的密码不一致";
        return false;
      }
    },
    checkEmail(result) {
      this.email.checkResult = result;
    },
    checkPassword(result) {
      this.password.checkResult = result;
    },
    checkInvite(result) {
      this.invitationCode.checkResult = result;
    },
    checkCaptcha(result) {
      this.captcha.checkResult = result;
    },
    async getCaptcha() {
      await user.getCaptcha().then(res => {
        if(res.data){
          this.captcha.src = res.data.imgdata;
          this.captcha.key = res.data.captchakey;
        }
      });
    },
    compareCode() {
      if (this.code !== "") {
        this.invitationCode.value = this.code;
      } else if (localStorage.getItem("code") !== null) {
        this.invitationCode.value = localStorage.getItem("code");
      } else {
        this.invitationCode.value = "";
      }
    },
    changeContactMode({ key }) {
      if (key === "weixin") {
        this.connect.mode = "weixin";
        this.connect.name = "微信";
      } else {
        this.connect.mode = "skype";
        this.connect.name = "skype";
      }
    },
    async reg() {
      // 调用类型检查
      this.$refs.email.checkInput();
      this.$refs.password.checkInput();
	  this.$refs.captcha.checkInput();
      this.checkCorrect();
      // 添加联系方式
      const connect = this.connect.mode + ":" + this.connect.value;
      // 打开loading
      this.loadingShow = true;
      if (
        this.password.checkResult &&
        this.email.checkResult &&
        this.captcha.checkResult &&
        this.checkCorrect()
      ) {
        const regData = await user
          .reg(this.email.value, this.password.value, this.captcha.value, this.captcha.key, connect)
          .then(res => res.data);
        // 关闭loading
        this.loadingShow = false;
        switch (regData.code) {
          case 0:
            // :TODO 注册成功
            // 提交store
            message.success("注册成功，请登录");
            window.open("https://www.mbbrowser.com/console/", "_self");
            break;
          case -3:
            this.email.error = true;
            this.email.errorTips = regData.msg;
            break;
          case -4:
            this.password.error = true;
            this.password.errorTips = regData.msg;
            break;
          case -9:
            this.captcha.error = true;
            this.captcha.errorTips = regData.msg;
            break;
          default:
            this.email.error = true;
            this.email.errorTips = regData.msg;
            break;
        }
        this.loadingShow = false;
      } else {
        this.loadingShow = false;
        return false;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getCaptcha();
    });
  }
};
</script>

<style scoped>
label {
  display: block;
}
.reg {
  max-width: 400px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 8px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
}
.reg-header {
  padding: 30px 30px 10px 30px;
}
.reg-header h1 {
  font-size: 21px;
  color: #3d4043;
  margin: 10px 0 0 0;
  font-weight: 500;
  padding: 0;
}
.form {
  margin-top: 10px;
  padding: 0 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.label-tips {
  font-size: 12px;
  color: #7f868a;
  padding: 5px 0;
}
.password-container,
.captcha-container {
  display: flex;
}
.password {
  margin-right: 20px;
}
.contact-container {
  margin-top: 5px;
}
.contact {
  display: flex;
  border: 1px #dadce0 solid;
  padding: 6px 8px;
  font-size: 15px;
  width: 100%;
  border-radius: 4px;
}
.contact-dropdown {
  margin-left: 5px;
  width: 60px;
  display: flex;
  align-items: center;
  color: #3d4043;
}
.contact-dropdown .contact-span {
  display: block;
  flex: 1;
}
.contact .contact-input {
  padding: 0 0 0 10px;
  border: 0;
  margin-left: 10px;
  background: none;
  flex: 1;
  border-left: 1px #dadce0 solid;
  outline: none;
}
.captcha-img {
  padding-top: 13px;
  margin-left: 20px;
}
.captcha-img img {
  width: 100px;
  height: 39px;
  cursor: pointer;
}
.operation {
  padding: 15px 0;
  display: flex;
  align-items: center;
}
.operation a {
  color: #1a73e7;
  font-weight: 500;
}
.operation .login {
  flex: 1;
}
.operation-button {
  background: #1d73e9;
  width: 140px;
  height: 40px;
  border: 1px #1a73e7 solid;
  border-radius: 4px;
  color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 2px;
  outline: none;
}
.reg .tips {
  background: #f8f9fa;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 20px;
}
</style>
