import service from "./axiosConfig.js";

//base
const base = {
  // 主服务器
  url: 'https://admin.mbbrowser.com'
  // url: "https://admin2.mbbrowser.com" // 测试服务器
};

export const user = {
  reg(accounts, pwd, captcha, captchakey, connect, invitecode) {
    return service.post(`${base.url}/api/mbbrowser/reg`, {
      accounts,
      pwd,
      captcha,
      captchakey,
      connect,
      invitecode
    });
  },
  getCaptcha() {
    return service.get(`${base.url}/api/captcha/show`);
  }
};
