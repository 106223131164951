<template>
  <div class="modules">
    <div class="modules-header">
      <img src="../assets/img/hello.png" width="200" alt="hello" />
      <h1>我们很荣幸向您推荐我们的套餐</h1>
      <h2>会员价格499元/月起</h2>
      <p>
        所有候鸟VIP均免费安全浏览器服务。升级到VIP方案，即表示您同意接受《候鸟浏览器服务条款》。
      </p>
    </div>
    <div class="items">
      <!--<div class="item">
        <div class="item-content">
          <div class="top">
            <h2>个人版</h2>
            <p>适用于个人</p>
            <div class="price">
              <a class="price-button" href="https://www.mbbrowser.com/console/">
                ￥499.00/6个月
              </a>
            </div>
            <div class="other">
              <h5>或选择其他周期</h5>
              <a class="other-button" href="https://www.mbbrowser.com/console/">
                ￥ 899.00 / 12个月
              </a>
            </div>
          </div>
          <div class="bottom">
            <h6>候鸟浏览器功能</h6>
            <ul>
              <li>1个用户</li>
              <li>20个浏览器环境</li>
              <li>数据云端同步</li>
              <li>多线程操作</li>
            </ul>
          </div>
        </div>
      </div>-->
      <div class="item color">
        <div class="item-content">
          <div class="top">
            <h2>个人无限版</h2>
            <p>适用于个人</p>
            <div class="price">
              <a class="price-button" href="https://www.mbbrowser.com/console/">
                ￥499.00/ 1个月
              </a>
            </div>
            <div class="other">
              <h5>或选择其他套餐</h5>
              <a class="other-button" href="https://www.mbbrowser.com/console/">
                ￥ 2550.00 / 6个月
              </a>
            </div>
          </div>
          <div class="bottom">
            <h6>候鸟浏览器功能</h6>
            <ul>
              <li>1个用户</li>
              <li>500个浏览器环境</li>
              <li>数据云端同步</li>
              <li>多线程操作</li>
              <li>无限制插件库批量分配</li>
              <li>自动化API服务器</li>
              <li>自动化脚本加密调用</li>
              <li>千款插件批量安装</li>
              <li>一键批量检测代理</li>
              <li>网站帐户批量管理</li>
              <li>自定义环境指纹模板</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="item-content">
          <div class="top">
            <h2>团队协作版</h2>
            <p>适用于团队</p>
            <div class="price">
              <a class="price-button" href="https://www.mbbrowser.com/console/">
                3用户/699元/月
              </a>
            </div>
            <div class="other">
              <h5>或选择优惠套餐</h5>
              <a class="other-button" href="https://www.mbbrowser.com/console/">
                5用户/999元/月
              </a>
              <a class="other-button" href="https://www.mbbrowser.com/console/">
                8用户/1599元/月
              </a>
              <a class="other-button" href="https://www.mbbrowser.com/console/">
                10用户/1999元/月
              </a>
            </div>
          </div>
          <div class="bottom">
            <h6>候鸟浏览器功能</h6>
            <ul>
              <li>3+N个用户</li>
              <li>1500-10000个浏览器环境</li>
              <li>团队子帐户协同管理</li>
              <li>团队帐户间环境分享</li>
              <li>团队帐户自动化API凭据</li>
              <li>数据云端同步</li>
              <li>多线程操作</li>
              <li>无限制分组</li>
              <li>无限制环境平铺</li>
              <li>无限制插件库批量分配</li>
              <li>自动化API服务器</li>
              <li>自动化脚本加密调用</li>
              <li>千款插件批量安装</li>
              <li>一键批量检测代理</li>
              <li>网站帐户批量管理</li>
              <li>自定义环境指纹模板</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="item-content">
          <div class="top">
            <h2>专属定制</h2>
            <p>更多用户，更多环境</p>
            <div class="price">
              <a class="price-button" href="https://www.mbbrowser.com/console/">
                联系客服
              </a>
            </div>
            <div class="other">
              <h5>更多定制服务请联系客服咨询</h5>
              <a class="other-button" href="https://www.mbbrowser.com/console/">
                自动化服务
              </a>
              <a class="other-button" href="https://www.mbbrowser.com/console/">
                环境包套餐
              </a>
            </div>
          </div>
          <div class="bottom">
            <h6>候鸟浏览器功能</h6>
            <ul>
              <li>20+用户</li>
              <li>10000+可定制浏览器环境</li>
              <li>数据云端同步</li>
              <li>多线程操作</li>
              <li>可选自动化服务</li>
              <li>本地环境批量导入</li>
              <li>自动化API服务器</li>
              <li>自动化脚本加密调用</li>
              <li>千款插件批量安装</li>
              <li>一键批量检测代理</li>
              <li>网站帐户批量管理</li>
              <li>自定义环境指纹模板</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThePrice"
};
</script>

<style scoped>
.modules {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
  padding: 0 20px;
}
.modules-header {
  text-align: center;
}
.modules-header h1 {
  margin-top: 40px;
}
.modules-header p {
  font-size: 14px;
}
title {
  font-size: 28px;
  font-weight: 400;
  margin: 10px 0;
}

.title-l {
  font-size: 20px;
  font-weight: 400;
  padding: 10px 0;
  margin: 10px 0;
}

.tips {
  font-size: 14px;
  color: #7f868a;
}

.item {
  width: 300px;
  box-shadow: 0 1px 6px rgba(60, 64, 67, 0.3);
  border-radius: 8px;
  background: #ffffff;
  margin: 12px 48px 12px 48px;
  text-align: center;
}

.color {
  border: 4px #1d73e9 solid;
}

.items {
  padding-top: 40px;
  display: flex;
}

.top {
  min-height: 200px;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;
  text-align: center;
  word-break: break-word;
}

.top h2 {
  margin: 50px 0 0 0;
  font-size: 26px;
  font-weight: 400;
}

.price-button {
  display: block;
  margin: 30px 0 0 0;
  min-width: 200px;
  color: #1d73e9;
  background: #ffffff;
  border: 1px #dadce0 solid;
  border-radius: 4px;
  padding: 6px 24px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.price-button:hover {
  background: #e8f0fe;
  color: #2065ca;
}

.other {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
}

.other h5 {
  font-size: 14px;
  font-weight: 400;
  margin: 5px 0;
}

.other-button {
  display: block;
  border: none;
  border-radius: 4px;
  padding: 8px 24px;
  font-size: 14px;
  color: #1d73e9;
  font-weight: 400;
  background: none;
  cursor: pointer;
}

.other-button:hover {
  background: #e8f0fe;
  color: #2065ca;
}

.bottom {
  text-align: left;
  margin: 20px;
  padding: 20px 0;
  border-top: 1px #dadce0 solid;
}

.bottom h6 {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}

.bottom ul {
  padding: 0;
}

.bottom ul li {
  list-style: none;
  font-size: 14px;
  padding: 5px 0;
}

.color .price-button {
  background-color: #1d73e9;
  color: #ffffff;
  border: none;
}

.color .price-button:hover {
  background-color: #2065ca;
}

.null .price-button {
  color: #7f868a;
  font-weight: 400;
}

.null .price-button:hover {
  background-color: #f0f0f0;
}

.null .other {
  color: #dadce0;
}

.null .other-button {
  color: #dadce0;
}

.null .other-button:hover {
  background: #ffffff;
}
@media only screen and (max-width: 980px) {
  .items {
    flex-wrap: wrap;
    justify-content: center;
  }
  .item {
    margin-bottom: 40px;
  }
}
</style>
