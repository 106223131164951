<template>
  <div class="shot-container">
    <div class="shot">
      <div class="info">
        <h2>为AMZ\FB多账号运营而生</h2>
        <h1>防关联浏览器</h1>
        <h3>1台设备 + 候鸟浏览器 = N个账号</h3>
        <a href="http://houniao.yalala.com/download.php" class="header-button"
          >下载候鸟浏览器</a
        >
        <h4 style="color:#EA4435;">每日10个名额免费试用，尽快下载注册吧</h4>
        <p>
          放弃1台设备1台VPS或虚拟机管理一个账号的土办法吧，使用候鸟浏览器对您的账号安全有大幅度提升。1台设备管理您的数千个账号,有条不紊,保持专注,并与团队保持共享同步
        </p>
      </div>
      <div class="register">
        <the-reg></the-reg>
      </div>
    </div>
  </div>
  <div class="pic">
    <img src="../assets/img/landing-pic.png" height="400" alt="working" />
  </div>
</template>

<script>
import TheReg from "@/components/TheReg";
export default {
  name: "TheShot",
  components: { TheReg }
};
</script>

<style scoped>
.shot-container {
  width: 100%;
  display: flex;
  padding-top: 100px;
  padding-bottom: 100px;
  max-height: 100vh;
  background: url("../assets/img/landing_bg.png") center center no-repeat;
  background-size: contain;
  justify-content: center;
}
.shot {
  max-width: 1000px;
  display: flex;
  align-items: center;
}
.info {
  width: 500px;
  color: #25272b;
  margin-right: 100px;
}
.info h2 {
  font-size: 36px;
  font-weight: 400;
  margin: 0;
}
.info h1 {
  font-size: 48px;
  margin: 0;
}
.info h3 {
  font-size: 24px;
  margin: 10px 0 0 0;
  font-weight: 400;
}
.info h4 {
  font-size: 16px;
  margin-top: 10px;
}
.info p {
  margin: 20px 0 0 0;
  font-size: 16px;
  color: #616161;
  line-height: 36px;
  font-weight: 300;
}
.pic {
  margin-top: -100px;
  z-index: 1;
}
.register {
  z-index: 999;
}
.header-button {
  display: block;
  background: #1d73e9;
  width: 140px;
  height: 40px;
  text-align: center;
  line-height: 38px;
  border: 1px #1a73e7 solid;
  border-radius: 4px;
  color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 2px;
  outline: #1a73e7;
}
@media only screen and (max-width: 980px) {
  .shot {
    flex-wrap: wrap;
    justify-content: center;
  }
  .info {
    width: auto;
    padding: 0 40px;
    text-align: center;
    margin-right: 0;
    margin-bottom: 40px;
  }
  .pic {
    margin-top: 20px;
  }
}
</style>
