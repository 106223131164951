<template>
  <div class="input-outline">
    <div class="input-outline-container">
      <div class="input-container">
        <div class="input">
          <label class="input-label">
            <input
              :type="childCheck"
              class="input-style"
              :class="{ 'input-error': childError, 'input-style-small': small }"
              autocomplete="off"
              :aria-label="defaultTitle"
              @focusin="
                isFocus = true;
                isSomething = false;
              "
              @focusout="focusOut($event.target.value)"
              v-model="childInputValue"
              @input="$emit('update:inputValue', $event.target.value)"
            />
          </label>
          <div
            class="tips"
            :class="{
              'tips-focus': isFocus,
              'tips-error': childError,
              'tips-focus-out': isSomething,
              'tips-small': small
            }"
          >
            {{ defaultTitle }}
          </div>
        </div>
      </div>
      <div class="error" v-show="childError">
        {{ childErrorTips }}
      </div>
    </div>
  </div>
</template>

<script>
//email
const email = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
const password = /^.{6,18}$/;
const invite = /^[a-zA-Z0-9]{4}$/;
const code = /^[a-zA-Z0-9]{4}$/;
export default {
  props: {
    /***
     * 默认内容&标题
     * String 必填
     * */
    defaultTitle: {
      type: String,
      required: true
    },
    // 是否错误
    error: {
      type: Boolean,
      default: false
    },
    // 错误提示
    errorTips: {
      type: String,
      required: false
    },
    // inputValue
    inputValue: {
      type: String,
      default: ""
    },
    // 大小
    type: {
      type: String,
      default: "big"
    },
    // 输入类型
    check: {
      type: String,
      default: "email"
    }
  },
  name: "InputOutline",
  data() {
    return {
      // 是否获得焦点
      isFocus: true,
      // 是否拥有内容
      isSomething: false,
      childError: this.error,
      childErrorTips: this.errorTips,
      childCheck: this.check,
      childInputValue: this.inputValue
    };
  },
  computed: {
    small() {
      return this.type === "small";
    }
  },
  methods: {
    focusOut(value) {
      if (value !== "") {
        this.isSomething = true;
        this.checkInput();
      } else {
        this.isFocus = false;
      }
    },
    checkInput() {
      const value = this.childInputValue;
      switch (this.check) {
        case "email":
          // 邮箱格式检查
          if (email.test(value)) {
            this.childError = false;
            this.$emit("check-result", true);
          } else {
            this.childError = true;
            this.$emit("check-result", false);
            this.childErrorTips = "请输入正确的邮箱格式";
          }
          break;
        case "password":
          // 密码检查
          if (password.test(value)) {
            this.childError = false;
            this.$emit("check-result", true);
          } else {
            this.$emit("check-result", false);
            this.childError = true;
            this.childErrorTips = "请输入不少于6位小于18位的密码";
          }
          break;
        case "invite":
          // 邀请码检查
          if (invite.test(value) || value === "") {
            this.childError = false;
            this.$emit("check-result", true);
          } else {
            this.$emit("check-result", false);
            this.childError = true;
            this.childErrorTips = "邀请码为4位大小写字母或数字";
          }
          break;
        case "captcha":
          // 图片验证码检查
          if (code.test(value) && value !== "") {
            this.childError = false;
            this.$emit("check-result", true);
          } else {
            this.$emit("check-result", false);
            this.childError = true;
            this.childErrorTips = "图片验证码为4位大小写字母或数字";
          }
          break;
        default:
          break;
      }
    }
  },
  watch: {
    error(newVal) {
      this.childError = newVal;
      this.childErrorTips = this.errorTips;
    },
    childInputValue(newVal) {
      if (newVal !== "") {
        this.isFocus = true;
      }
    },
    inputValue(newVal) {
      this.childInputValue = newVal;
    }
  }
};
</script>

<style scoped>
.input-outline {
  padding: 13px 0 0 0;
}

.input-outline-container {
  position: relative;
}

.input-style {
  display: block;
  border-radius: 4px;
  color: #202124;
  font-size: 17px;
  margin: 1px;
  padding: 13px 15px;
  border: 1px solid #dadce0;
  flex: 1;
}

.input-style-small {
  padding: 6px 8px;
  font-size: 15px;
  width: 100%;
}

.input-error {
  margin: 0;
  border: 2px #d93025 solid;
  color: #d93025;
}

.input-error:focus {
  border: 2px #d93025 solid !important;
}

.input-style:focus {
  margin: 0;
  outline: none;
  border: 2px #1d73e9 solid;
}

.input-label {
  width: 100%;
  display: flex;
}

.tips {
  pointer-events: none;
  position: absolute;
  background: #ffffff;
  top: 17px;
  box-sizing: border-box;
  color: #80868b;
  font-size: 17px;
  font-weight: 500;
  left: 8px;
  padding: 0 8px;
  white-space: nowrap;
  border-radius: 8px;
  z-index: 1;
  transition: top 0.3s ease, font-size 0.4s ease, color 0.2s;
}

.tips-small {
  font-size: 14px;
  top: 7px;
}

.tips-focus {
  top: -8px;
  font-size: 10px;
  color: #1d73e9;
  transition: top 0.3s ease, font-size 0.4s ease, color 0.2s;
}

.tips-focus-out {
  color: #202124;
}

.tips-error {
  color: #d93025;
}

.error {
  padding: 2px 0;
  font-size: 13px;
  color: #d93025;
  text-align: left;
  font-weight: 400;
}
</style>
