<template>
  <the-header />
  <the-shot />
  <the-programme />
  <the-modules />
  <the-price />
  <the-footer />
</template>

<script>
import TheHeader from "./components/TheHeader";
import TheShot from "./components/TheShot";
import TheProgramme from "./components/TheProgramme";
import TheModules from "./components/TheModules";
import ThePrice from "./components/ThePrice";
import TheFooter from "./components/TheFooter";
export default {
  name: "App",
  components: {
    TheFooter,
    ThePrice,
    TheModules,
    TheShot,
    TheHeader,
    TheProgramme
  }
};
</script>

<style>
#app {
  overflow: hidden;
}
body {
  margin: 0;
}
</style>
