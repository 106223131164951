<template>
  <div class="programme">
    <header class="programme-header">
      <img src="../assets/img/amazon.png" width="60" alt="amazon" />
      <h2>AMZ小号多开防关联指纹浏览器</h2>
      <h1>
        2021年最新基于chrome内核反指纹防追踪检测技术原理打造的小号多登系统，为您的AMZ\FB等账号保驾护航！
      </h1>
    </header>
    <div class="content">
      <img
        src="../assets/img/programme.png"
        alt="programme"
        class="content-img"
        width="800"
        height="566"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "TheProgramme"
};
</script>

<style scoped>
.programme-header {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 0 20px;
}
.programme-header h2 {
  font-size: 30px;
  font-weight: 500;
  margin-top: 30px;
}
.programme-header h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 60px;
}
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  margin-top: 100px;
  overflow: hidden;
}
.content-img {
  width: 90%;
  max-width: 1000px;
  height: auto;
}
</style>
