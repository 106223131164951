<template>
  <footer class="footer">
    <div class="init">
      <div class="logo">
        <img src="../assets/img/logo-black.png" width="42" alt="logo" />
        <span>候鸟浏览器版权所有 @2023</span>
      </div>
      <div class="wechat">
        <div class="kefu">
          <img src="../assets/img/kefu.png" width="120" alt="ehouniao" />
        </div>
        <div class="shangwu">
          <img src="../assets/img/shangwu.png" width="120" alt="mbbrowser" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "TheFooter"
};
</script>

<style scoped>
.footer {
  display: flex;
  margin-top: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  background: #f8f9fa;
  align-items: center;
  justify-content: center;
}
.footer span {
  margin-top: 20px;
}
.wechat {
  display: flex;
  justify-content: center;
}
.wechat .kefu {
  margin-right: 40px;
}
.init {
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.logo {
  flex: 1;
  display: flex;
  align-items: center;
}
.logo span {
  margin: 0 0 0 10px;
}
@media only screen and (max-width: 680px) {
  .init {
    justify-content: center;
  }
  .logo {
    width: 100%;
    justify-content: center;
  }
  .wechat {
    width: 100%;
    margin-top: 40px;
    text-align: center;
  }
}
</style>
