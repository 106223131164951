<template>
  <header class="header-container">
    <div class="header">
      <div class="logo">
        <img src="../assets/img/logo.png" height="36" alt="候鸟" />
      </div>
      <nav class="nav"></nav>
      <div class="action">
        <a href="http://houniao.yalala.com/download.php" class="header-button"
          >下载候鸟浏览器</a
        >
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "TheHeader"
};
</script>

<style scoped>
.header-container {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 4px;
  display: flex;
  justify-content: center;
}
.header {
  height: 64px;
  display: flex;
  max-width: 1400px;
  width: 100%;
  padding: 0 20px;
  align-items: center;
}
.header .nav {
  flex: 1;
}
.header .action {
  display: flex;
  align-items: center;
}
.header .action a {
  color: #1d73e9;
  font-weight: 500;
  font-size: 14px;
  margin-right: 20px;
}
.header .action .header-button {
  display: block;
  background: #1d73e9;
  width: 140px;
  height: 40px;
  text-align: center;
  line-height: 38px;
  border: 1px #1a73e7 solid;
  border-radius: 4px;
  color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 2px;
  outline: #1a73e7;
}
@media only screen and (max-width: 600px) {
  .connect {
    display: none;
  }
}
</style>
