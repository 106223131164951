<template>
  <div class="modules">
    <header class="modules-header">
      <img src="../assets/img/star.png" width="60" alt="star" />
      <h1>防关联和修改指纹只是基础功能</h1>
      <p>候鸟浏览器为您想得更多,在安全快捷方面我们永不满足。</p>
    </header>
    <div class="list">
      <div class="module">
        <img src="../assets/img/dock1.png" width="44" height="47" />
        <div class="info">
          <h2>指纹保护·独家首创的魔术指纹</h2>
          <p>
            候鸟浏览器以最佳的方式修改浏览器指纹。让网站读取和您真实指纹不同的“魔术指纹”，通过这种方法代替网站任何读取你真实指纹的方法。
          </p>
        </div>
      </div>
      <div class="module">
        <img src="../assets/img/dock2.png" width="44" height="44" />
        <div class="info">
          <h2>团队协作·浏览器环境随时随地共享</h2>
          <p>
            候鸟支持多个子账号共享浏览器环境及账号相关数据。不受时间、空间限制，随时随地团队协作共享，团队之间互不干扰，有效提升工作效率。
          </p>
        </div>
      </div>
      <div class="module">
        <img src="../assets/img/dock4.png" width="44" height="32" />
        <div class="info">
          <h2>时时同步·方便简洁的养号流程</h2>
          <p>
            候鸟浏览器可以将配置好的浏览器环境通过配置文件的方式导入导出，同时加密保存在云端。通过云端同步浏览器环境配置文件的功能，您可以在任何您登录候鸟账号的地方获得完整安全的工作体验，账号的登录信息甚至tab页都会为您一一打开:
            )
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheModules"
};
</script>

<style scoped>
.modules {
  flex-direction: column;
  margin-top: 120px;
  padding: 0 20px;
}
.modules-header {
  text-align: center;
}
.modules-header h1 {
  margin-top: 40px;
}
.modules-header p {
  font-size: 24px;
}
.module {
  display: flex;
  width: 100%;
  max-width: 1200px;
  padding: 40px 20px;
  border-bottom: 1px #dadce0 dashed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.module .info {
  margin-left: 20px;
}
.module .info h2 {
  font-weight: 400;
  margin-top: 40px;
}
.module .info p {
  color: #7f868a;
  font-size: 14px;
  line-height: 32px;
}
.modules .list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
}
</style>
